import React from "react"
import { Helmet } from "react-helmet"
import "./events.css"
import "../components/partner.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import CaepLogo from "../images/logos/caep-logo.png"
import EmmaHead from "../images/gatsby-icon.png"
import event_img from '../images/events-img/card-img/elas-segep-min.jpg'
import MyFooter from "../components/footer.js"
import MyNavbar from "../components/navbar.js"
import EventBanner from "../components/event-banner.js"

const EventsPage = () => (
  <div className="index">
    <Helmet>
      <title>Elas na Segep</title>
      <link rel="icon" href={EmmaHead} />
    </Helmet>
      <MyNavbar />
      <EventBanner 
        mobile_img = {event_img}
        img = {event_img}
        name = "Elas na SEGEP: 2o Workshop de Programação em Blocos (CAEP/Poli-USP)"
        date = "Setembro/2019"
        description = "O Workshop de Programação em Blocos visa promover o contato das alunas com a lógica e a programação em blocos, através da interface de programação Scratch. As aulas são baseadas em conteúdo expositivo (através de slides) e interativo (no formato de quiz através do site Kahoot), sendo as alunas acompanhadas durante a resolução de exercícios.    O segundo oferecimento do workshop, realizado em parceria com o Centro Acadêmico de Engenharia de Produção da Escola Politécnica (CAEP/Poli-USP) durante a Semana de Engenharia de Produção (SEGEP), teve duração de 4h e atingiu 23 meninas do 9o ano do ensino fundamental da Escola Estadual Emygdio de Barros."
      />
      <div className="partner-container">
        <div className="partner-title">PARCERIAS</div>
        <div className="partner-flex">
            <div className="partner-element">
                <img src={CaepLogo} alt="" className="partner-logo"/>
                <p className="partner-name">CAEP</p>
            </div>
        </div>
      </div>
      <MyFooter />
  </div>
)

export default EventsPage
